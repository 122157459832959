import React, { useState, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { Country, State, City }  from 'country-state-city';


function AddCompany() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        company_name: yup.string().required("Company Name Required"),
        // company_code: yup.string().required("Company Code Required"),
        country: yup.string().required("Country Name Required"),
        state: yup.string().required("State Name Required"),
        city: yup.string().required("City Name Required"),
        address: yup.string().required("Address Required"),
    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });
  

   
    const [countrycode, setCountrycode] = useState();
    const [citycode, setCitycode] = useState();     

    
    
    let countryData = Country.getAllCountries();
    console.log(countryData)


    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);  
    
   
    
    
    const obj = useContext(NoteContext)   


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/ManageCompany");
    }   


    const onSubmit = async (data) => {    
        console.log(data)
        const companydatainfo = {
            companyName : data["company_name"],
            country : data["country"],
            state : data["state"],
            city : data["city"],
            address : data["address"],
            userId: localStorage.getItem("userId"),         
        } 
        console.log(companydatainfo)   
            await axios.post(config.mongo_endpoint+'api/v1/company/add', companydatainfo, 
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded',
                         'x-access-token':window.localStorage.getItem("AgentToken")} })
                        .then(result => {
                          console.log(result)
                          if(result.status == 200 && result.data.status == "Success"){
                            swal("Company Information Added Successfully.", {
                                icon: "success",
                            });
                             
                            LandingScreen();
    
                          } else {
                            swal("Some Thing went wrong try again later.", {
                                icon: "warning",
                            });
                           
                          }
                          
                          
                        })
                        .catch(error => {
                          // handle the error
                          console.error(error);
                          if (error) {
                            swal("Some Thing went wrong try again later.", {
                                icon: "warning",
                            });
                          
                  
                          }
                        
                        });

    }

  
  

    // const getCountryList = () => {
    //     let data = countrycitystateHandler.getCountries();
    //     console.log(data);
    //     let obj = {};
    //     let countryListData = [];
    //     data.forEach((country, index) => {
    //         obj = {
    //             value: `${country.name}`,
    //             label: `${country.name}`
    //         };
    //         countryListData.push(obj);
    //     });
    //     setCountrylist(countryListData);
    // }


    // const getStateList = () => {
    //     let data = State.getStatesOfCountry("IN");
    //     console.log(data);
    //     // let obj = {};
    //     // let stateListData = [];
    //     // data.map((item, index) => {
    //     //     obj = {
    //     //         value: `${item}`,
    //     //         label: `${item}`
    //     //     };
            
    //     //     stateListData.push(obj);
    //     // })
    //     // setStatelist(stateListData);
    // }

    // const getCityList = () => {
    //     let data = City.getCitiesOfState('IN', 'TN');
    //     console.log(data);
    //     let obj = {};
    //     let cityData = [];
    //     data.map((item, index) => {
    //         obj = {
    //             value: `${item}`,
    //             label: `${item}`
    //         };
    //         cityData.push(obj);
    //     })
    //     setCitylist(cityData);
    // }
    {

    }


const handleCountry = (event) =>{
    console.log(event)
    const datacountry = event.target.value.split("|")
    console.log(datacountry)
    setCountrycode(datacountry[0]);
    setStateData(State.getStatesOfCountry(datacountry[0]))
}
console.log(stateData)


const handleState = (event) =>{
    console.log(event)
    const datastate = event.target.value.split("|")
    console.log(datastate)    
    setCityData(City.getCitiesOfState(countrycode, datastate[0])); 
    console.log(City.getCitiesOfState(countrycode, datastate[0])); 
}
console.log(cityData)




const handleCity = (event) =>{
    console.log(event)
    setCitycode(event);
}





    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Add Company</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                    <Grid container spacing={3} marginTop={1}>
                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="company_name"
                                                                label="Company Name *"
                                                                {...sailorInfoHandler("company_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['company_name']}
                                                                helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                                            />
                                                        </Grid>                                                        

                                                        {/* <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="company_code"
                                                                label="Company Code"
                                                                {...sailorInfoHandler("company_code")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['company_code']}
                                                                helperText={sailorInfoErrors['company_code'] ? sailorInfoErrors['company_code'].message : ''}
                                                            />
                                                        </Grid> */}
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Country *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="country"
                                                                    label="Country *"
                                                                    {...sailorInfoHandler("country")}
                                                                    error={!!sailorInfoErrors['country']}
                                                                    helperText={sailorInfoErrors['country'] ? sailorInfoErrors['country'].message : ''}
                                                                    onChange={handleCountry}
                                                                >
                                                                    {/* <MenuItem value="Country">Country</MenuItem> */}
                                                                    {
                                                                        countryData.map((list) => (                                                                           
                                                                            <MenuItem value={`${list.isoCode}|${list.name}`}>{list.name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.country && <FormHelperText error={!!sailorInfoErrors['country']}>{sailorInfoErrors.country.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">State *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="state"
                                                                    label="State *"
                                                                    {...sailorInfoHandler("state")}
                                                                    error={!!sailorInfoErrors['state']}
                                                                    helperText={sailorInfoErrors['state'] ? sailorInfoErrors['state'].message : ''}
                                                                    onChange={handleState}
                                                                >
                                                                    {/* <MenuItem value="Country">State</MenuItem> */}
                                                                    {
                                                                        stateData.map((list) => (
                                                                            <MenuItem value={`${list.isoCode}|${list.name}`}>{list.name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.state && <FormHelperText error={!!sailorInfoErrors['state']}>{sailorInfoErrors.state.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">City *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="city"
                                                                    label="City *"                                                                   
                                                                    {...sailorInfoHandler("city")}
                                                                    error={!!sailorInfoErrors['city']}
                                                                    helperText={sailorInfoErrors['city'] ? sailorInfoErrors['city'].message : ''}
                                                                    onChange={handleCity}
                                                                >
                                                                    {/* <MenuItem value="City">City</MenuItem> */}
                                                                    {
                                                                        cityData.map((list) => (                                                                          
                                                                            <MenuItem value={`${list.name}`}>{list.name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.city && <FormHelperText error={!!sailorInfoErrors['city']}>{sailorInfoErrors.city.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>                                                                                                                
                                                    </Grid>

                                                    <Grid container spacing={3} marginTop={1}>                                                                                                      
                                                        <Grid item sm={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                name="address"
                                                                label="Address*"
                                                                multiline
                                                                minRows={3}
                                                                maxRows={10}
                                                                {...sailorInfoHandler("address")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['address']}
                                                                helperText={sailorInfoErrors['address'] ? sailorInfoErrors['address'].message : ''}
                                                            />
                                                        </Grid>

                                                    </Grid>

                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default AddCompany;