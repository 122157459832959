import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import BadgeIcon from '@mui/icons-material/Badge';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";

import { Grid, Box } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Modal } from '@mui/material';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';
import companyImage from '../../assets/images/aes-logo.png';

function ManageVisitors() {

  const [data, setData] = useState([]);
  const printRef = useRef();

  const [companyData, setCompanyData] = useState([]);
  const handlePrint = () => {
    const printContent = printRef.current;
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"><title>Print</title>');
    // Add any styles needed for the print content
    printWindow.document.write(' <style>tr {line-height: 18px !important;}</style><style>@media print { body { -webkit-print-color-adjust: exact; border: "1px solid black"} }</style>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };
  async function getApprovedOnboardingList(filterDate) {
    const company = {
      "company_code": `${localStorage.getItem('code')}`
    }
    let data = await fetch(config.mongo_endpoint + "api/v1/visitor/viewallvisitor", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        // "x-access-token": `${localStorage.getItem('AgentToken')}`

      },
      data: company
    });
    let res = await data.json();
    console.log(res)
    if (res.status === "Success") {
      const userType = `${localStorage.getItem('type')}`;
      console.log(userType);
      const company_code = `${localStorage.getItem('code')}`;

      if(userType === 'User'){
        // alert()
        const filteredRecords = res.data.filter(record => {
          const checkinDate = new Date(record.checkin);
          const company_id = record.company_id;
          return checkinDate.getFullYear() === filterDate.getFullYear() &&
            checkinDate.getMonth() === filterDate.getMonth() &&
            checkinDate.getDate() === filterDate.getDate() && company_id === company_code;
        });
        setData(filteredRecords);
      } else {
        const filteredRecords = res.data.filter(record => {
          const checkinDate = new Date(record.checkin);
          return checkinDate.getFullYear() === filterDate.getFullYear() &&
            checkinDate.getMonth() === filterDate.getMonth() &&
            checkinDate.getDate() === filterDate.getDate();
        });
        setData(filteredRecords);
      }
      
      
      // setData(res.data)

    }

  }
  const [visitorPass, setVisitorPasss] = useState({});
  const [visitorPassNo, setVisitorPassNo] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = async (resp,passNo) => {
    setOpen(true);
    setVisitorPasss(resp);
    setVisitorPassNo(passNo);
    setCompanyData(localStorage.getItem('name'))
    await fetchPhoto(resp);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleSubmit = () => {
  //   // Handle submission logic here
  //   handleClose();
  // };



  useEffect(() => {
    const filterDate = new Date();
    getApprovedOnboardingList(filterDate)

  }, [])


  async function handleDate(event) {
    const filterDate = new Date(event);
    getApprovedOnboardingList(filterDate)

  }
  const [img, setImg] = useState()
  const fetchPhoto = async(visitors) => {
    setImg('');
    const response = {'visitor_id': visitors.visitorId}
    await axios.post(config.mongo_endpoint+"api/v1/visitor/viewPhotoByOnBoardingID", response, { headers: {   'Content-Type': 'application/x-www-form-urlencoded',} })
          .then(result => {
            console.log("photo",result.data);
            if(result.status == 200){
              
              setImg(config.imagePath+result.data);
              
            } 
          })
          .catch(error => {
            // handle the error
            console.error(error);
            if (error) {
              console.log("Something went wrong!")
            //   toast.error("Something went wrong. Please try again Later....", {
            //   position: toast.POSITION.TOP_CENTER,
            //   theme: "colored",
            // });
    
            }
          
          });
  }

  async function updateCheckout(data) {
    try {
      // console.log(data);
      // let visitor_request = new FormData();
      // visitor_request.append("Email" , data["email_Id"]);
      // visitor_request.append("Email" , data["email_Id"]);
      // visitor_request.append("Email" , data["email_Id"]);

      const visitorInformation = {
        "visitor_id": data.visitorId,
      }
      await axios.post(config.mongo_endpoint + 'api/v1/visitor/checkOut', visitorInformation)
        .then(result => {
          // console.log(result);
          if (result.status === 200 && result.data.status === "Success") {
            // toast.success("Registration Completed Successfully.", {
            //   position: toast.POSITION.TOP_CENTER,
            //   theme: "colored",
            // });
            // LandingScreen();

            swal("Checkout Success....!", {
              icon: "success",
            });
          } else {
            // toast.success("Duplicate Date Exist", {
            //   position: toast.POSITION.TOP_CENTER,
            //   theme: "colored",
            // });
            swal("Checkout failed....!", {
              icon: "warning",
            });
          }
        })
      const filterDate = new Date();
      getApprovedOnboardingList(filterDate)

    }
    catch (error) {
      console.error(error);
      // if (error)
      //   setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);

    }

  }


  return (
    <>

      <AgentMenu></AgentMenu>

      <div className="container-fluid pr-0" id="main-area">

        <Navbar />

        <div className="container-fluid">
          <div className="d-flex border-bottom pb-15 pt-15">
            <div className="me-auto ">
              <h2 className="page-title">Manage Visitors </h2>
            </div>
            <div>
              <Link to="/AddVisitor" className="nav-link" >
                <Button variant="contained" color="success">
                  Check In
                </Button></Link></div>
          </div>
          <section className="voting-section">
            <div className="container">
              <div className="row">
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {/* Left Section */}
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              label="Visitor Date *"
                              // value={visitor_date}
                              // {...sailorInfoHandler("date_of_birth")}
                              // defaultValue={visitor_date}
                              slotProps={{ textField: { size: 'small' } }}
                              onChange={handleDate}
                              format="DD-MM-YYYY"
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          </section>
          <div className="table-responsive m-tbl">
            <TableContainer component={Paper} style={{ marginTop: '15px' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                <TableHead>
                  <TableRow>

                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Company </TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Vistor Name </TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Vistor Company </TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Phone No</TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Host</TableCell>
                    {/* <TableCell align="center" style={{ background: '#ddd' }}>Purpose of Visit</TableCell> */}
                    <TableCell align="center" style={{ background: '#ddd' }}>Check In Time</TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Check Out Time</TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>No.of.Hours</TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>
                    <TableCell align="center" style={{ background: '#ddd' }}>Badge</TableCell>



                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 ? (<TableRow><TableCell align="center" colSpan={8} style={{ padding: '5px 15px' }}> No Record Found</TableCell></TableRow>) : ""}
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center" style={{ padding: '5px 15px' }}> {index + 1}</TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}> {row.company_name}</TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}> {row.visitor_name}</TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}> {row.visitor_company_name}</TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}> {row.phone_no}</TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}>{row.meeting_person} </TableCell>
                      {/* <TableCell align="center" style={{ padding: '5px 15px' }}>{row.purpose_of_visit} </TableCell> */}
                      <TableCell align="center" style={{ padding: '5px 15px' }}>{row.checkin ? (<Moment format="DD-MM-YYYY hh:mm A">{row.checkin}</Moment>) : ""} </TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}>{row.checkout ? (<Moment format="DD-MM-YYYY hh:mm A">{row.checkout}</Moment>) : ""} </TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}>{row.checkout ? (
                                                                          <Moment duration={row.checkin}
                                                                          date={row.checkout}
                                                                          format="h [Hrs] m [Mins]"
                                                                  />
                                                                 
                                                                        ) : ""} </TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}>{
                        row.checkout === null ? (<Button variant="contained" size="small" onClick={() => { updateCheckout(row); }}>CheckOut</Button>) : (<DoneIcon ></DoneIcon>)
                      }
                      </TableCell>
                      <TableCell align="center" style={{ padding: '5px 15px' }}> <BadgeIcon onClick={() => { handleOpen(row,index + 1) }} />
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </div>
        {open && (
        <Modal
            size={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="visitor-pass-modal-title"
            aria-describedby="visitor-pass-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
          
            <div style={{  
                alignItems: 'center',
                justifyContent: 'center', maxHeight: '800px', background: '#fff', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                    
                    <div ref={printRef}>
      {/* <table ref={printRef} border="0" cellPadding="0" cellSpacing="0" style={{ width: '1200px', borderCollapse: 'collapse', color:'black' }}>
        <tr>
          <td style={{ border: '1px solid #909090', padding: '5px' }}>
            <table width="100%" border="0" cellPadding="0" cellSpacing="1">
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <img src={companyImage} alt="Logo" style={{ height: '80px' }} />
                </td>
                <td style={{ textAlign: 'center', fontSize: '24px', fontFamily: 'Arial, sans-serif', lineHeight: '1.2' }}>
                  <strong style={{ fontSize: '20px', padding: '0px 5px' }}>{visitorPass.company_name}</strong><br />
                  <span style={{ fontSize: '15px', padding: '0px 5px', lineHeight: '1.2' }}>{visitorPass.address}</span><br />
                  <strong style={{ fontSize: '20px', padding: '0px 5px' }}>Visitor Pass</strong>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <img src={companyImage} alt="Logo" style={{ height: '80px' }} />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>
          <td style={{ border: '1px solid #909090', padding: '5px' }}>
            <table width="100%" border="0" cellPadding="0" cellSpacing="1">
              <tr>
                <td style={{ width: '25%', border: '1px solid #000', padding: '2px 5px' }}><b>Visitor Name</b></td>
                <td style={{ width: '50%', padding: '2px 10px', border: '1px solid #000' }}> {visitorPass.visitor_name}</td>
                <td rowSpan="7" colSpan="2" style={{ textAlign: 'center', padding: '2px 10px' }}>
                  <img src={visitorPass.imagepath} alt="Visitor" style={{ width: '200px', height: '200px'}} />
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #000', padding: '0 5px' }}><b>Visitor Company</b></td>
                <td style={{ border: '1px solid #000', padding: '0 10px' }}>{visitorPass.visitor_company_name}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #000', padding: '0 5px' }}><b>Contact No</b></td>
                <td style={{ border: '1px solid #000', padding: '0 10px' }}>{visitorPass.phone_no}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #000', padding: '0 5px' }}><b>Visitor Valid ID Proof ({visitorPass.proof_id.map((res,index) => res)})</b></td>
                <td style={{ border: '1px solid #000', padding: '0 10px' }}>{visitorPass.document_no}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #000', padding: '0 5px' }}><b>Whom to Meet</b></td>
                <td style={{ border: '1px solid #000', padding: '0 10px' }}>{visitorPass.meeting_person}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #000', padding: '0 5px' }}><b>Date & Time</b></td>
                <td rowSpan="2" style={{ padding: '0' }}>
                  <table width="100%" border="0" cellPadding="0" cellSpacing="1">
                    <tr>
                      <td style={{ width: '33.33%', border: '1px solid #000', padding: '0 10px' }}><Moment format="DD-MM-YYYY hh:mm A">{visitorPass.checkin}</Moment></td>
                      <td style={{ width: '33.33%', border: '1px solid #000', padding: '5px 10px' }}><b>Out Time</b></td>
                      <td style={{ width: '33.33%', border: '1px solid #000',padding: '0 10px' }}></td>
                    </tr>
                    <tr>
                      <td style={{ width: '33.33%', border: '1px solid #000', padding: '0 10px' }}></td>
                      <td style={{ width: '33.33%', border: '1px solid #000', padding: '5px 10px' }}><b>Employee Signature</b></td>
                      <td style={{ width: '33.33%', border: '1px solid #000', padding: '0 10px' }}></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #000', padding: '0 5px' }}><b>Visitor Signature </b></td>
              </tr>
            </table>
          </td>
        </tr>
      </table> */}
     <div style={{
  width: '377px',
  height: '566px',
  zIndex: 35,
  overflow: 'hidden',
  border: '2px solid #000000',
  background: '#FFFFFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <div style={{
    width: '377px',
    height: '566px',
    paddingBottom: '57px',
    background: 'url() no-repeat center/100% 100%',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <div style={{
      width: '377px',
      height: '103px',
      paddingBottom: '1.5px',
      paddingTop: '4px',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column'
    }}>
      <div style={{
        width: '361px',
        height: '94px',
        marginLeft: '13px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <img src={companyImage} alt="Company Logo" style={{  marginLeft: '15px',width: '70px',display: 'flex',height: '50px',marginTop: '23px'}} />
        <div style={{
          width: '254px',
          height: '74px',
          marginTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}>
          <span style={{
            width: '247px',
            height: '24px',
            color: '#000000',
            fontSize: '20px',
            fontWeight: 700,
            fontFamily: 'Inter',
            lineHeight: 1.4,
            whiteSpace: 'nowrap',
            display: 'flex'
          }}>
            {visitorPass.company_name}
          </span>
          <span style={{
            width: '247px',
            height: '43px',
            marginLeft: '7px',
            color: '#000000',
            fontSize: '11px',
            fontFamily: 'Inter',
            lineHeight: 1.4,
            display: 'flex'
          }}>
            {visitorPass.address}
          </span>
        </div>
      </div>
      <div style={{
        width: '376.01px',
        marginLeft: '-0.01px',
        border: '1px solid #000000',
        display: 'flex'
      }}></div>
    </div>
    <span style={{
      width: '94px',
      height: '19px',
      marginTop: '2px',
      marginLeft: '141px',
      color: '#000000',
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: 1.4,
      whiteSpace: 'nowrap',
      display: 'flex'
    }}>
      Visitor Pass
    </span>
    <div style={{
      width: '376.01px',
      marginTop: '2.75px',
      marginLeft: '-0.01px',
      border: '1px solid #000000',
      display: 'flex'
    }}></div>
    {/* <div style={{
      width: '123px',
      height: '135.95px',
      marginTop: '12.25px',
      marginLeft: '127px',
      background: '#FFFBFB',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src={img} alt="Visitor" style={{ width: '108.41px', height: '118.75px' }} />
    </div> */}
   <div style={{
      width: '216px', // adjusted width to accommodate the number box
      height: '135.95px',
      marginTop: '12.25px',
      marginLeft: '127px',
      background: '#FFFBFB',
      display: 'flex',
      alignItems: 'center'
    }}>
    <div style={{
      width: '108.41px',
      height: '118.75px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
        <img src={img} alt="Visitor" style={{ width: '100%', height: '100%' }} />
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '53px',
    }}>
        <span style={{
          padding: '10px',
          border: '3px solid black',
          fontSize: '23px',
          color: '#333'
        }}>{visitorPassNo}</span>
        <span style={{
          fontSize: '16px',
          color: '#333',
          marginTop: '5px' // adds space between the number and the text
        }}><b>Pass No</b></span>
    </div>
</div>



    <div style={{
      width: '377px',
      marginTop: '3.05px',
      border: '1px solid #000000',
      display: 'flex'
    }}></div>
    <div style={{
      width: '234px',
      height: '15px',
      marginTop: '9px',
      marginLeft: '14px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '76px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Visitor Name
      </span>
      <span style={{
        width: '111px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.visitor_name}
      </span>
    </div>
    <div style={{
      width: '268px',
      height: '16px',
      marginTop: '14px',
      marginLeft: '14px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '99px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Visitor Company
      </span>
      <span style={{
        width: '145px',
        height: '16px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.visitor_company_name}
      </span>
    </div>
    <div style={{
      width: '197px',
      height: '15px',
      marginTop: '14px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '67px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Contact No
      </span>
      <span style={{
        width: '73px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.phone_no}
      </span>
    </div>
    <div style={{
      width: '201px',
      height: '15px',
      marginTop: '10px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '107px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Visitor ID Proof
      </span>
      <span style={{
        width: '77px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.document_no}
      </span>
    </div>
    <span style={{
      width: '63px',
      height: '11px',
      marginLeft: '25px',
      color: '#000000',
      fontSize: '10px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: 1,
      whiteSpace: 'nowrap',
      display: 'flex'
    }}>
      ({visitorPass.proof_id.map((res,index) => res)})
    </span>
    <div style={{
      width: '343px',
      height: '19px',
      marginTop: '11px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <span style={{
        width: '86px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Whom to Meet
      </span>
      <span style={{
        width: '222px',
        height: '19px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.meeting_person}
      </span>
    </div>
    <div style={{
      width: '343px',
      height: '25px',
      marginTop: '11px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <span style={{
        width: '86px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
       Purpose of Visit
      </span>
      <span style={{
        width: '222px',
        height: '25px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1,
        whiteSpace: 'normal',
        display: 'flex'
      }}>
        {visitorPass.purpose_of_visit}
      </span>
    </div>
    <span style={{
      width: '86px',
      height: '15px',
      marginTop: '9px',
      marginLeft: '14px',
      color: '#000000',
      fontSize: '13px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: 1.4,
      whiteSpace: 'nowrap',
      display: 'flex'
    }}>
      Date & Time
    </span>
    <div style={{
      width: '346px',
      height: '15px',
      marginTop: '8px',
      marginLeft: '18px',
      display: 'flex',
      alignItems: 'center'
    }}>
      <span style={{
        width: '18px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        In :
      </span>
      <span style={{
        width: '125px',
        height: '15px',
        marginLeft: '6px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        <Moment format="DD-MM-YYYY hh:mm A">{visitorPass.checkin}</Moment>
      </span>
      <span style={{
        width: '28px',
        height: '15px',
        marginLeft: '37px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Out :
      </span>
      <span style={{
        width: '125px',
        height: '15px',
        marginLeft: '7px',
        color: '#000000',
        fontSize: '13px',
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        {visitorPass.checkout != null && (<Moment format="DD-MM-YYYY hh:mm A">{visitorPass.checkout}</Moment>) }
      </span>
    </div>
    <div style={{
      width: '377.02px',
      marginTop: '13px',
      marginLeft: '-0.01px',
      border: '1px solid #000000',
      display: 'flex'
    }}></div>
    <div style={{
      width: '324px',
      height: '15px',
      marginTop: '7px',
      marginLeft: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <span style={{
        width: '99px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Visitor Signature
      </span>
      <span style={{
        width: '118px',
        height: '15px',
        color: '#000000',
        fontSize: '13px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: 1.4,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}>
        Employee Signature
      </span>
    </div>
  </div>
</div>

    </div>
                {/* </div> */}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button variant="contained" className="no-print" onClick={handlePrint} size="small">Print Pass</Button>
                </Grid>
            </div>
        </Modal>
        )}


      </div>






    </>
  );
}

export default ManageVisitors;