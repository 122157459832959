import React, { useState, useEffect , useContext } from "react";
import { Link , useNavigate} from "react-router-dom";
import ELogo from "../../assets/images/aes-logo.png";
import ELogo1 from "../../assets/images/aes-logo.png";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Visitors from "../../assets/images/sailor/visitor.png";
import Dashboard from "../../assets/images/sailor/dashboard.png";
import Report from "../../assets/images/sailor/report.png";
import Company from "../../assets/images/sailor/company.png";
import Logout from "../../assets/images/sailor/logout.png";
import Close from "../../assets/images/close.svg";

import NoteContext from "../../context/NoteContext";
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';

function AgentMenu() {
    const obj = useContext(NoteContext);
	const navigate = useNavigate();

    // const getSessionData=()=>{
        const loggedIn = window.localStorage.getItem('loggedIn');
	    const userType = window.localStorage.getItem('type');
        // if(userType === 'User'){
        //     alert()
        // }
    //     console.log(obj);
    //     // alert(userType)
    //     if((agentToken=="" || agentToken==null)){
    //         localStorage.clear();
    //         navigate("/");

    //     }
    // }
    // useEffect(() => {
    //     getSessionData();        
    // },[getSessionData])


    function logout(){
        // alert();
              swal({
            title: "Are you sure you want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    window.localStorage.clear()
                    window.localStorage.setItem('loggedIn',false)

                    obj.setToken('')
                    obj.setName('');
                 navigate("/");
                    swal("Logout Success....!", {
                        icon: "success",
                    });
                } 
            });
     
      }
      const removeBodyClass = () => {
        document.body.classList.remove('m-menu'); 
      };
  
    return (
        <>

            <div className="sidebar">
             <Link className="m-close" onClick={removeBodyClass}>
                <img src={Close} alt=""/>
            </Link>
                <div className="det-bar">
                    <div className="main-title">
                        <Link to="/">
                        <img
                            src={ELogo1}
                            className="img-fluid logo-txt"
                            alt=""
                            width="100"
                            height="93"
                            style={{ display: 'block', margin: 'auto' }}
                            />
                        </Link>
                    </div>


                    <ul>
                        {loggedIn && (
                            <li className="active">
                            <Link to="/VisitorDashboard" className="nav-link" >
                                <img src={Dashboard} alt="" className="m-ic" />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        )
                        }
                        {loggedIn && userType === 'User' && (
                            <>
                            <li>
                                <Link to="/ManageVisitors" className="nav-link">
                                    <img src={Visitors} alt="" className="m-ic" />
                                    <span>Visitors</span>
                                </Link>
                            </li>
                             <li> 
                             <Link to="/VisitorReport" className="nav-link" >
                                 <img src={Report} alt="" className="m-ic" /><span>Reports</span>
                                 </Link>
                            </li>
                            </>
                        )}


                        {loggedIn && userType === 'Admin' && (
                            <>
                                <li> 
                                <Link to="/ManageCompany" className="nav-link" >
                                    <img src={Company} alt="" className="m-ic" /><span>Company</span>
                                    </Link>
                            </li>
                                <li> 
                                <Link to="/VisitorReport" className="nav-link" >
                                    <img src={Report} alt="" className="m-ic" /><span>Reports</span>
                                    </Link>
                            </li></>
                        )}    
                        {loggedIn && (
                        <li> 
                            <Link to="#" class="nav-link"onClick={()=>{logout()}} >
                                <img src={Logout} alt="" className="m-ic"  /><span>Log Out</span>
                            </Link>
                        </li>
                        )}

                    </ul>
                </div>

            </div>

        </>
    );
}

export default AgentMenu;