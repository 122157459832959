import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, IconButton } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import { format } from 'date-fns';  // Import format function from date-fns

const DataTable = ({ data, handleOpen }) => {
  const [filters, setFilters] = useState({
    company_name: '',
    visitor_name: '',
    visitor_company_name: '',
    phone_no: '',
    meeting_person: '',
    checkin: '',
    checkout: '',
  });
  const [filteredRows, setFilteredRows] = useState([]);

  // Function to calculate the duration based on checkin and checkout
  const calculateDuration = (checkin, checkout) => {
    const checkinDate = new Date(checkin);
    const checkoutDate = new Date(checkout);
    if (checkinDate && checkoutDate && checkinDate < checkoutDate) {
      const durationInMinutes = Math.floor((checkoutDate - checkinDate) / (1000 * 60)); // duration in minutes
      return {
        duration: durationInMinutes,
        formattedDuration: `${Math.floor(durationInMinutes / 60)} Hr ${durationInMinutes % 60} Mins`,
      };
    }
    return {
      duration: 0,
      formattedDuration: '',
    };
  };

  useEffect(() => {
    // Apply the duration calculation to each row in the original data
    const rowsWithDuration = data.map((item, index) => {
      const { duration, formattedDuration } = calculateDuration(item.checkin, item.checkout);

      // Ensure the date is properly formatted using date-fns
      const formattedCheckin = item.checkin ? format(new Date(item.checkin), 'dd-MM-yyyy hh:mm a') : '';
      const formattedCheckout = item.checkout ? format(new Date(item.checkout), 'dd-MM-yyyy hh:mm a') : '';

      return {
        ...item,
        id: item._id,
        index: index + 1,
        formattedCheckin,  // Use formatted date here
        formattedCheckout,  // Use formatted date here
        formattedDuration,
        duration,  // Store duration in minutes
      };
    });

    setFilteredRows(rowsWithDuration);
  }, [data]);  // This will run whenever the `data` prop changes

  const handleFilterChange = (field, value) => {
    const newFilters = { ...filters, [field]: value };
    setFilters(newFilters);

    // Apply filters and recalculate durations
    const filteredData = data.filter((row) => {
      return Object.keys(newFilters).every((key) => {
        if (newFilters[key]) {
          return row[key] && String(row[key]).toLowerCase().includes(newFilters[key].toLowerCase());
        }
        return true;  // If the filter value is empty, include all rows for that field
      });
    });

    const rowsWithDuration = filteredData.map((item, index) => {
      const { duration, formattedDuration } = calculateDuration(item.checkin, item.checkout);

      // Format checkin and checkout fields
      const formattedCheckin = item.checkin ? format(new Date(item.checkin), 'dd-MM-yyyy hh:mm a') : '';
      const formattedCheckout = item.checkout ? format(new Date(item.checkout), 'dd-MM-yyyy hh:mm a') : '';

      return {
        ...item,
        id: item._id,
        index: index + 1,
        formattedCheckin,  // Use formatted date here
        formattedCheckout,  // Use formatted date here
        formattedDuration,
        duration,  // Store duration in minutes
      };
    });

    setFilteredRows(rowsWithDuration);
  };

  const handleCellEditCommit = (params) => {
    const { field, value, id } = params;

    const updatedRows = filteredRows.map(row => {
      if (row.id === id) {
        const newRow = { ...row };

        // Update the changed field value
        newRow[field] = value;

        // Recalculate the duration based on checkin and checkout values
        const { duration, formattedDuration } = calculateDuration(newRow.checkin, newRow.checkout);
        newRow.duration = duration;
        newRow.formattedDuration = formattedDuration;

        return newRow;
      }
      return row;
    });

    setFilteredRows(updatedRows);
  };

  const baseUrl = 'https://visitpro.acvhrms.com:8443/visitor/';

  const columns = [
    { field: 'index', headerName: '#', width: 70 },
    {
      field: 'company_name',
      headerName: 'Company Name',
      width: 150,
      renderHeader: () => (
        <TextField
          label="Company Name"
          variant="standard"
          value={filters.company_name}
          onChange={(e) => handleFilterChange('company_name', e.target.value)}
          size="small"
        />
      ),
    },
    {
      field: 'visitor_name',
      headerName: 'Visitor Name',
      width: 150,
      renderHeader: () => (
        <TextField
          label="Visitor Name"
          variant="standard"
          value={filters.visitor_name}
          onChange={(e) => handleFilterChange('visitor_name', e.target.value)}
          size="small"
        />
      ),
    },
    // {
    //   field: 'imagepath',
    //   headerName: 'Photo',
    //   width: 150,
    //   renderHeader: () => (
    //     <TextField
    //       label="Photo"
    //       variant="standard"
    //       value={filters.imagepath}
    //       onChange={(e) => handleFilterChange('imagepath', e.target.value)}
    //       size="small"
    //     />
    //   ),
    //   renderCell: (params) => (
    //     <img
    //       src={`${baseUrl}${params.row.imagepath}`}
    //       alt="Photo"
    //       style={{ width: '100%', height: 'auto' }}
    //     />
    //   ),
    // },
    {
      field: 'visitor_company_name',
      headerName: 'Visitor Company',
      width: 150,
      renderHeader: () => (
        <TextField
          label="Visitor Company"
          variant="standard"
          value={filters.visitor_company_name}
          onChange={(e) => handleFilterChange('visitor_company_name', e.target.value)}
          size="small"
        />
      ),
    },
    {
      field: 'phone_no',
      headerName: 'Phone No',
      width: 130,
      renderHeader: () => (
        <TextField
          label="Phone No"
          variant="standard"
          value={filters.phone_no}
          onChange={(e) => handleFilterChange('phone_no', e.target.value)}
          size="small"
        />
      ),
    },
    {
      field: 'meeting_person',
      headerName: 'Meeting Person',
      width: 130,
      renderHeader: () => (
        <TextField
          label="Meeting Person"
          variant="standard"
          value={filters.meeting_person}
          onChange={(e) => handleFilterChange('meeting_person', e.target.value)}
          size="small"
        />
      ),
    },
    {
      field: 'checkin',
      headerName: 'Check In',
      width: 170,
      editable: true,
      renderHeader: () => (
        <TextField
          label="Check IN"
          variant="standard"
          value={filters.checkin}
          onChange={(e) => handleFilterChange('checkin', e.target.value)}
          size="small"
        />
      ),
      renderCell: (params) => format(new Date(params.value), 'dd-MM-yyyy hh:mm a'),  // Ensure proper formatting here
    },

    {
      field: 'checkout',
      headerName: 'Check Out',
      width: 170,
      editable: true,
      renderHeader: () => (
        <TextField
          label="Check Out"
          variant="standard"
          value={filters.checkout}
          onChange={(e) => handleFilterChange('checkout', e.target.value)}
          size="small"
        />
      ),
      renderCell: (params) => format(new Date(params.value), 'dd-MM-yyyy hh:mm a'),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 150,
      renderCell: (params) => params.row.formattedDuration,  // Display formatted duration
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpen(params.row)}>
          <BadgeIcon />
        </IconButton>
      ),
    }
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnMenu
        autoHeight
        getRowId={(row) => row._id}
        onCellEditCommit={handleCellEditCommit}  // Listen for cell edits
      />
    </div>
  );
};

export default DataTable;