import React from 'react'
import { useContext } from 'react'
import { Link } from "react-router-dom";
import NoteContext from '../../context/NoteContext'
import Ham from "../../assets/images/sailor/ham.svg";

export default function Navbar() {
    const obj = useContext(NoteContext) 
    const userName = window.localStorage.getItem('name'); 
    const toggleBodyClass = () => {
      document.body.classList.toggle('m-menu');
    };
  return (
    <div class="main-header">

        <div class="main-header-left ">
        <Link> <img onClick={toggleBodyClass} src={Ham} alt="" />  </Link>

        </div>
        {}
        <div class="main-header-right">
            <p>Welcome, <span>{userName}</span></p>
        </div>

    </div>
  )
}
