import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {  useForm, Controller } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from "../Navbar";
import Webcam from "react-webcam";
import swal from "sweetalert";
import Moment from 'react-moment';

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user"
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 0,
  },
  preview: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320 },
  delete: {
    cursor: "pointer",
    padding: 15,
    background: "red",
    color: "white",
    border: "none",
  },
};


function AddAgent() {
  var FormData = require('form-data');
  // const { AgentId } = match.params;
  // const isAddMode = !AgentId;
  // =================== Voter Info Tab-1 Validation  ================

  const sailorInformation = yup.object().shape({
    visitor_name: yup.string().required("Enter Visitor Name").matches(/^[A-Za-z\s]*$/, "Only Alphabets allowed"),
    email_Id: yup.string(),
    mobile_number: yup
      .string()
      .required("Enter Contact No")
      .matches(
        /^[0-9]{10,15}$/,
        'Phone number must be between 10 to 15 digits'
      ), // Use regex pattern to validate phone number format,
    meeting_person: yup.string().required("Enter Meeting Person"),
    visitor_type: yup.string().required("Select Visitor Type"),
    purpose_of_visit: yup.string().required("Enter Purpose of Visit"),
    proof: yup.string().required("Select Proof"),
    document_no: yup.string().required("Enter Document No"),
    visitor_company: yup.string(),
  })

  const visitorCheck = yup.object().shape({
    mobile_number: yup
      .string()
      .required("Enter Contact No")
      .matches(
        /^[0-9]{10,15}$/,
        'Phone number must be between 10 to 15 digits'
      ), // Use regex pattern to validate phone number format,
    profile_image: yup.mixed().required("Capture Visitor Image")
  })


  // =================== Voter Info Tab-1 Validation  ================
  // =================== Voter Info Yup Resolver Starts  ================
  const { register: sailorInfoHandler,
    handleSubmit: sailorInfoHandlerSubmit,
    control, 
    formState: { errors: sailorInfoErrors } } = useForm({
      resolver: yupResolver(sailorInformation),
      mode: 'OnSubmit',
    });

  const { register: visitorCheckHandler,
    handleSubmit: visitorCheckHandlerSubmit,
    setValue,
    formState: { errors: visitorCheckErrors } } = useForm({
      resolver: yupResolver(visitorCheck),
      mode: 'OnSubmit',
    });

  const [selectedImage, setSelectedImage] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {

    getCompanyList();
  }, []);

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const obj = useContext(NoteContext)

 
 
  const navigate = useNavigate();
  const LandingScreen = () => {
    navigate("/ManageVisitors");
  }



  const [picture, setPicture] = useState('')
  const [faceMatchStatus, setfaceMatchStatus] = useState(false)
  const [showVisitEntry, setshowVisitEntry] = useState(false);
  const [showVisitEntrys, setshowVisitEntrys] = useState(true);
  const [visitorStatus, setvisitorStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [NewVisitor, setNewVisitor] = useState();
  const [faceMatchChecking, setfaceMatchChecking] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  
  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc);
    const base64Data = pictureSrc;
    const [imageType, imageData] = base64Data.split(";base64,");
    const blob = base64ToBlob(imageData, imageType);
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const fileName = `image_${timestamp}.jpg`;
    const temporaryFile = new File([blob], fileName, { type: imageType });
    setValue('profile_image', temporaryFile);
    setNewVisitor(temporaryFile);
    dateTimeCalc();
  })

  const dateTimeCalc = () => {
    const date = new Date(); // Assuming the input date is in UTC
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZoneName: 'short',
    };
    const formattedDate = date.toLocaleString('en-IN', options); // Using 'en-IN' for timezone 'GMT +05:30' 
    // setcaptureDateTime(formattedDate);
  }
  const handleDateChange = (formdate) => {
    if (formdate) {
      const date = new Date(formdate);
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    }
  }


  const getCompanyList = async () => {
    let companyData = await fetch(config.mongo_endpoint + "api/v1/proof/getProof", {
      method: "GET",
    });

    let resultCompanyData = await companyData.json();
    if (resultCompanyData.status === 'Success') {
      const companylist = resultCompanyData.data;
      console.log(companylist)
      setData(companylist);

    }
    // console.log(resultCompanyData)
  }
  const [visitorData, setVisitorData] = useState({});
  const [visitFlag, setvisitFlag] = useState(0);
  const base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
const [lastVisits, setLastVisits] = useState([]);
const onVisitSubmit = async (data) => {
  try {
    console.log(data);

  setfaceMatchChecking(true);
  const faceMatchData = new FormData();
  faceMatchData.append("file",NewVisitor);
  // faceMatchData.append("phonenumber",data["mobile_number"]);
  
   const faceMatchCheck = await axios.post(config.face_match_api + '/find_face', faceMatchData);
   
  //  console.log(visitorLastTransactions);
   const company_code = localStorage.getItem('code');

     if (faceMatchCheck.status === 200 && faceMatchCheck.data.status === "success" && faceMatchCheck.data.matched_face.length > 0) {
    //   const visitorInformationa = {
    //     "mobile_number": data["mobile_number"],
    //     "company_code" :company_code
    //  }
    //   const visitorSearches = await axios.post(config.mongo_endpoint + 'api/v1/visitor/search', visitorInformationa);
    //   // console.log("visitor Search => ", visitorSearchs.data);
    //   if(visitorSearches.status === 200 && visitorSearches.data.status === "Success"){
       
    //       swal("Mobile No Already In-Use. Please Enter Alternate Number.", {
    //         icon: "warning",
    //     });
    //        setfaceMatchChecking(false);
       
    //    } else {
          

        setvisitFlag(1);
        const lastVisits = {
          "mobile_number":faceMatchCheck.data.matched_face[0]
        }
         const visitorLastTransactions = await axios.post(config.mongo_endpoint + 'api/v1/visitor/lastVisits', lastVisits);
         setLastVisits(visitorLastTransactions.data.data)

      const visitorInformation = {
        "mobile_number": faceMatchCheck.data.matched_face[0],
        "company_code" :company_code
     }
       const visitorSearch = await axios.post(config.mongo_endpoint + 'api/v1/visitor/search', visitorInformation);
       console.log("visitor Search => ", visitorSearch.data);
      if(visitorSearch.status === 200 && visitorSearch.data.status === "Success"){
        if(visitorSearch.data.data.checkout == null && visitorSearch.data.data.checkin != null){
          swal("Visitor Already Checked-In at "+ visitorSearch.data.data.company_name + "  via Mobile No : "+visitorSearch.data.data.phone_no , {
            icon: "warning",
        });
        setfaceMatchChecking(false);
        } else {
          setfaceMatchChecking(false);
          setshowVisitEntry(true);
          setshowVisitEntrys(false);
          setvisitorStatus(false)
          setVisitorData(visitorSearch.data.data);
          setProof(visitorSearch.data.data.proof_id);
          setValue('proof',visitorSearch.data.data.proof_id)
        }
        
      //  console.log("VisitorData",visitorData);
      } else if(visitorSearch.status === 200 && visitorSearch.data.status === "Failed"){
        setfaceMatchChecking(false);
        setvisitorStatus(true);
          setshowVisitEntry(true);
          setshowVisitEntrys(false);          
         setVisitorData( {
          "phone_no": faceMatchCheck.data.matched_face[0],
       });
        
       
      } else {
        swal("Something Went Wrong! Try Again");
      }
      //  }
      
      
     } else if (faceMatchCheck.status === 200 && faceMatchCheck.data.status === "success" && faceMatchCheck.data.matched_face.length === 0) {

              const visitorInformation = {
                "mobile_number": data["mobile_number"],
                "company_code" :company_code
            }
              const visitorSearch = await axios.post(config.mongo_endpoint + 'api/v1/visitor/search', visitorInformation);
              console.log("visitor Search => ", visitorSearch.data);
              if(visitorSearch.status === 200 && visitorSearch.data.status === "Success"){
                if(visitorSearch.data.data.checkout == null && visitorSearch.data.data.checkin != null){
                  swal("Visitor Already Checked-In at "+ visitorSearch.data.data.company_name + "  via Mobile No : "+visitorSearch.data.data.phone_no , {
                    icon: "warning",
                });
                setfaceMatchChecking(false);
                } else {
                  setfaceMatchChecking(false);
                  setshowVisitEntry(true);
                  setshowVisitEntrys(false);
                  setvisitorStatus(false)
                  setVisitorData(visitorSearch.data.data);
                  setProof(visitorSearch.data.data.proof_id);
                  setValue('proof',visitorSearch.data.data.proof_id)
                }
                setvisitFlag(1);
                const lastVisits = {
                  "mobile_number":data["mobile_number"],
                }
                const visitorLastTransactions = await axios.post(config.mongo_endpoint + 'api/v1/visitor/lastVisits', lastVisits);
                setLastVisits(visitorLastTransactions.data.data)
              
              } else if(visitorSearch.status === 200 && visitorSearch.data.status === "Failed"){
                  setvisitFlag(2);
                  setfaceMatchChecking(false);
                  setvisitorStatus(true);
                  setshowVisitEntry(true);
                  setshowVisitEntrys(false);          
                  setVisitorData( {
                    "phone_no": data["mobile_number"],
                });
                

              }
    //   const visitorInformations = {
    //     "mobile_number": data["mobile_number"],
    //     "company_code" :company_code
    //  }
    //   const visitorSearchs = await axios.post(config.mongo_endpoint + 'api/v1/visitor/search', visitorInformations);
    //  // console.log("visitor Search => ", visitorSearchs.data);
    //  if(visitorSearchs.status === 200 && visitorSearchs.data.status === "Success"){
      
    //      swal("Mobile No Already In-Use. Please Enter Alternate Number.", {
    //        icon: "warning",
    //    });
    //       setfaceMatchChecking(false);
      
    //   } else {
          // setvisitFlag(2);
          // setfaceMatchChecking(false);
          // setvisitorStatus(true);
          // setshowVisitEntry(true);
          // setshowVisitEntrys(false);          
          // setVisitorData( {"phone_no": data["mobile_number"]});
      // }
          
    } else if (faceMatchCheck.status === 500){
      setvisitFlag(2);
      setfaceMatchChecking(false);
      setvisitorStatus(true);
        setshowVisitEntry(true);
        setshowVisitEntrys(false);          
    //    setVisitorData( {
    //     "phone_no": data["mobile_number"],
    //  });

    } else {
      swal("FaceMatch Failed! Try Again");
      setfaceMatchChecking(false);
     }

  }
  catch (error) {
    console.error(error);
    if (error) {
      alert("Something went wrong. Please try again Later....")
    }
    console.log(error.code);

  }
}

  const onSubmit = async (data, event) => {
    try {
      // console.log(data);
      event.preventDefault(); 
      setSubmitStatus(true);
      const company_name = localStorage.getItem('name');
      const company_code = localStorage.getItem('code');
    
        const visitorInformation = new FormData();
        visitorInformation.append("email_id",data["email_Id"]);
        visitorInformation.append("visitor_name",data["visitor_name"]);
        visitorInformation.append("phone_no",data["mobile_number"]);
        visitorInformation.append("company_id",company_code);
        visitorInformation.append("company_name",company_name);
        visitorInformation.append("meeting_person",data["meeting_person"]);
        visitorInformation.append("visitor_type",data["visitor_type"]);
        visitorInformation.append("proof",data["proof"]);
        visitorInformation.append("document_no",data["document_no"]);
        visitorInformation.append("purpose_of_visit",data["purpose_of_visit"]);
        visitorInformation.append("visitor_company",data["visitor_company"]);
        visitorInformation.append("checkflag", visitFlag);
        visitorInformation.append("file",NewVisitor);
        if(visitFlag == 2){
          const faceMatchData = new FormData();
          faceMatchData.append("file",NewVisitor);
          faceMatchData.append("phonenumber",data["mobile_number"]);
           await axios.post(config.face_match_api + '/add_face', faceMatchData);
        }
 
     
          await axios.post(config.mongo_endpoint + 'api/v1/visitor/checkIn', visitorInformation)
        .then(result => {
          // console.log(result);
          if (result.status === 200 && result.data.status === "Success") {
            swal("Check In Succes....!", {
              icon: "success",
          });
            LandingScreen();
            // alert();
          } else {
            swal("Visitor Entry failed. Try Again....!", {
              icon: "warning",
          });
          setSubmitStatus(false);
          }
        })
    }
    catch (error) {
      console.error(error);
      // if (error)
      // setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);

    }

  }
 
  const [MobileNo, setMobileNo] = useState();
  const [proofType, setProof] = useState(0);
  async function handleMobileNo(e) {
    // console.log(e.target.value);
    setMobileNo(e.target.value);
  }

  async function handleProofChange(e) {
    console.log(e)
    setProof(e.target.value);
    setValue('proof',e.target.value);
    // setValue('document_no',null);
};

  return (
    <>
      <AgentMenu></AgentMenu>
      <div className="container-fluid pr-0" id="main-area">
        <Navbar />
        <div className="container-fluid">
          <div className="d-flex border-bottom pb-15 pt-15">
            <div className="me">
              <h2 className="page-title">Add Visitor</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb  m-0">
                  {/* <li className="breadcrumb-item"><a href="#">breadcrumb 01</a></li>
                <li className="breadcrumb-item"><a href="#">breadcrumb 02</a></li> */}
                  {/* <li className="breadcrumb-item active" aria-current="page">Update Details </li> */}
                </ol>
              </nav>
            </div>

          </div>
          <section className="voting-section">
            <div className="container">
              <div className="row">

                {/* <h1>Voting</h1> */}
                <Box sx={{ width: '100%', marginBottom: "5%" }}>
                  <>


                    
                      <Box>
                        <Grid container spacing={2}>
                        {
                            showVisitEntrys === true && (
                          <Grid item xs={12}>
                          <form onSubmit={visitorCheckHandlerSubmit(onVisitSubmit)}>
                            <Grid item xs={12}>


                              <div className="video-content">
                                <div className="video-div">
                                  <div className="webcam-out">
                                    {faceMatchStatus === false ? (
                                      <div className="webcam-image">
                                        {picture === "" ? (
                                          <Webcam
                                            audio={false}
                                            height={280}
                                            ref={webcamRef}
                                            width={600}
                                            screenshotFormat="image/jpeg"
                                            videoConstraints={videoConstraints}                                            
                                          />
                                        ) : (
                                          <img src={picture} {...sailorInfoHandler("purpose_of_visit")} />
                                        )}
                                        <div className="video-btn">
                                          <div className="video-btn-lft">
                                            <button
                                              className="btn-black"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setPicture("");
                                                setfaceMatchChecking(false);
                                                setValue('profile_image', "");
                                              }}
                                            >
                                              Retake
                                            </button>
                                            <button
                                              className="btn-org"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                capture();
                                              }}
                                            >
                                              Capture
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                    ) : (<div style={styles.container}>
                                      {selectedImage && (
                                        <div style={styles.preview}>
                                          <img
                                            src={URL.createObjectURL(selectedImage)}
                                            style={styles.image}
                                            alt="Thumb"
                                          />
                                          <button onClick={removeSelectedImage} style={styles.delete}>
                                            Remove This Image
                                          </button>
                                        </div>
                                      )}

                                    </div>)}

                                  </div>
                                </div>
                                {visitorCheckErrors.profile_image && <FormHelperText error={!!visitorCheckErrors['profile_image']}>{visitorCheckErrors.profile_image.message}</FormHelperText>}

                              </div>


                            </Grid>
                            <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="mobile_number"
                                    label="Phone"
                                    {...visitorCheckHandler("mobile_number")}
                                    onChange={handleMobileNo}
                                    id="outlined-error"
                                    error={!!visitorCheckErrors['mobile_number']}
                                    helperText={visitorCheckErrors['mobile_number'] ? visitorCheckErrors['mobile_number'].message : ''}
                                    inputProps={{ maxLength: 10, autoComplete: "off" }}
                                  />
                                </Grid>
                            <Grid container justifyContent="center">
                              {
                                faceMatchChecking === true ? (<Box sx={{ width: '100%' }}>
                                <LinearProgress />
                                <Typography variant="body1" align="center" mt={2}>Face Match Checking...</Typography>
                              </Box>) : (<Box display="flex" justifyContent="center" style={{ marginTop: '15px' }}>
                                <Button
                                      type="submit"
                                      variant="contained"
                                      sx={{ marginTop: "20px", marginLeft: "auto" }} // Align to the right
                                    >
                                      Check Visitor Info
                                    </Button>
                                    
                                </Box>)
                              }
                              
                              
                            </Grid>
                            </form>
                          </Grid>
                      )}
                          {
                            showVisitEntry === true && (
                            <>
                              <Grid item xs={8} >
                                {/* Left Section */}
                                <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    type="text"
                                    name="visitor_name"
                                    label="Visitor Name *"
                                    defaultValue={visitorData.visitor_name ? visitorData.visitor_name : ""}
                                    {...sailorInfoHandler("visitor_name")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['visitor_name']}
                                    helperText={sailorInfoErrors['visitor_name'] ? sailorInfoErrors['visitor_name'].message : ''}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    size="small"
                                    fullWidth
                                    type="text"
                                    name="email_Id"
                                    label="Email ID"
                                    defaultValue={visitorData.email_id ? visitorData.email_id : ""}
                                    ref={sailorInfoHandler}
                                    {...sailorInfoHandler("email_Id")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['email_Id']}
                                    helperText={sailorInfoErrors['email_Id'] ? sailorInfoErrors['email_Id'].message : ''}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="mobile_number"
                                    label="Phone *"
                                    defaultValue={visitorData.phone_no ? visitorData.phone_no : ""}
                                    {...sailorInfoHandler("mobile_number")}
                                    onChange={handleMobileNo}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['mobile_number']}
                                    helperText={sailorInfoErrors['mobile_number'] ? sailorInfoErrors['mobile_number'].message : ''}
                                    inputProps={{ maxLength: 10 }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="visitor_company"
                                    defaultValue={visitorData.visitor_company_name ? visitorData.visitor_company_name : ""}
                                    label="Visitor Company Name *"
                                    {...sailorInfoHandler("visitor_company")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['visitor_company']}
                                    helperText={sailorInfoErrors['visitor_company'] ? sailorInfoErrors['visitor_company'].message : ''}
                                  
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="meeting_person"
                                    label="Meeting Person *"
                                    {...sailorInfoHandler("meeting_person")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['meeting_person']}
                                    helperText={sailorInfoErrors['meeting_person'] ? sailorInfoErrors['meeting_person'].message : ''}
                                  
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl fullWidth style={{ marginTop: '15px' }}>
                                    <InputLabel id="demo-simple-select-label">Visitor Type*</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      size="small"
                                      name="visitor_type"
                                      label="Visitor Type *"
                                      {...sailorInfoHandler("visitor_type")}
                                      error={!!sailorInfoErrors['visitor_type']}
                                      helperText={sailorInfoErrors['visitor_type'] ? sailorInfoErrors['visitor_type'].message : ''}
                                    >
                                      <MenuItem value="1">Meeting</MenuItem>
                                      <MenuItem value="2">Enquiry</MenuItem>
                                      <MenuItem value="3">Courier </MenuItem>
                                      <MenuItem value="4">Interview </MenuItem>
                                      <MenuItem value="5">Suppliers</MenuItem>
                                      <MenuItem value="6">Maintenance</MenuItem>
                                      <MenuItem value="7">Others</MenuItem>
                                    </Select>
                                    {sailorInfoErrors.visitor_type && <FormHelperText error={!!sailorInfoErrors['visitor_type']}>{sailorInfoErrors.visitor_type.message}</FormHelperText>}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  
                                <FormControl fullWidth style={{ marginTop: '15px' }}>
                                <InputLabel id="demo-simple-select-label">Identity Proof</InputLabel>
                                <Controller
                              name="proof"
                              control={control} // Make sure control is defined
                              defaultValue={visitorData.proof_id ? visitorData.proof_id : ""}
                              rules={{ required: "Identity Proof is required" }} // Add required rule here
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  label="Identity Proof"
                                  size="small"
                                  onChange={(e) => {
                                    field.onChange(e); // react-hook-form's onChange
                                    handleProofChange(e); // Your custom onChange
                                  }}
                                  error={!!sailorInfoErrors['proof']}
                                >
                                  <MenuItem value="">Select Identity Proof</MenuItem>
                                  {data.map((res) => (
                                    <MenuItem value={res.proofId} key={res.proofId}>
                                      {res.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />

                                {sailorInfoErrors.proof && (
                                  <FormHelperText error={!!sailorInfoErrors['proof']}>
                                    {sailorInfoErrors.proof.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                                </Grid>
                                {proofType > 0 && (
                                  <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="document_no"
                                    label="Document No *"
                                    {...sailorInfoHandler("document_no")}
                                    defaultValue={visitorData.document_no ? visitorData.document_no : ""}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['document_no']}
                                    helperText={sailorInfoErrors['document_no'] ? sailorInfoErrors['document_no'].message : ''}
                                    // inputProps={{ maxLength: 10 }}
                                  />
                                </Grid>
                                )}
                                
                                <Grid item xs={12}>
                                  
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    name="address"
                                    label="Purpose Of Visit *"
                                    multiline
                                    minRows={3}
                                    maxRows={10}
                                    {...sailorInfoHandler("purpose_of_visit")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['purpose_of_visit']}
                                    helperText={sailorInfoErrors['purpose_of_visit'] ? sailorInfoErrors['purpose_of_visit'].message : ''}
                                  />
                                </Grid>
                                <Grid item xs={6} >
                                {
                                  submitStatus === true ? (
                                    <>
                                      <LinearProgress sx={{ width: '200%', marginTop:'10px' }} /> {/* Adjust width as needed */}
                                      <Typography variant="body1"  mt={2} style={{ color:'red'}}>
                                        Visitor Information Submitting...
                                      </Typography>
                                      </>
                                  ) : (
                                    
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ marginTop: "20px", marginLeft: "auto" }} // Align to the right
                                      >
                                        SUBMIT
                                      </Button>
                                   
                                  )
                                }

                                 
                                </Grid>
                                </form>
                              </Grid>
                             <Grid item xs={4} >
                             
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Avatar variant={"rounded"} alt="The image" src={picture} style={{ width: 200, height: 200 }} />
                            </div>

                            <Stack sx={{ width: '100%',marginTop: '15px' }} spacing={2}>
                              {visitorStatus === true ? (<Alert variant="filled" severity="success">
                                New Visitor
                              </Alert>) : (<Alert variant="filled" severity="info">
                                Already Visitor
                              </Alert>)}
                            </Stack>

                            
                            <TableContainer component={Paper} sx={{ minWidth: 200, paddingTop:"20px", fontSize:'13px'}}>
                            <InputLabel style={{ fontWeight:600, fontSize:'15px', color:'black' }}>Previous Visits </InputLabel>
                                <Table sx={{ minWidth: 200, paddingTop:"20px", fontSize:'12px'}} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Date & Time </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company/Unit </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Meeting Person</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {lastVisits.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (

                                      lastVisits.map((purpose, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{<Moment format="DD-MM-YYYY hh:mm A">{purpose.checkin}</Moment>}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.company_name}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.meeting_person}</TableCell>
                                            </TableRow>
                                        ))
                                    )}

                                    </TableBody>
                                </Table>
                            </TableContainer>  
                              </Grid>
                              </>
                            )}
                        </Grid>


                      </Box>

                  



                  </>

                </Box>
              </div>
            </div>
          </section>

        </div >
      </div >
    </>
  );
}

export default AddAgent;