import React, { useState, useEffect, useContext } from "react";

import Dash1 from "../../assets/images/sailor/dash-ico-1.svg";
import Dash2 from "../../assets/images/sailor/dash-ico-2.svg";
import Dash3 from "../../assets/images/sailor/dash-ico-3.svg";
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactApexChart from 'react-apexcharts';
import NoteContext from "../../context/NoteContext";
import { Grid, Box } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

function VisitorDashboard() {

    const usertype = window.localStorage.getItem("type");
    const obj = useContext(NoteContext);
    const [companyDatas, setCompanyDatas] = useState([]);
    const [purposeData, setPurposeData] = useState([]);
    const [visitorData, setvisitorData] = useState([]);
    const [companywiseData, setCompanyWiseData] = useState([]);
    const [hostData, setHostData] = useState([]);
   
    const [chartData, setChartData] = useState({
        series: [], // This will hold your series data
        options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          title: {
            text: 'Company/Unit Wise Visitors (this Month)',
            align: 'left'
          },
          legend: {
            tooltipHoverFormatter: function(val, opts) {
              return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            categories: [], // Placeholder for x-axis labels
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: '#f1f1f1',
          }
        }
      });
    const [pieData, setPieData] = useState({

        series: [],
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: [],
          colors: ['#FF4560', '#00E396', '#008FFB', '#FEB019', '#775DD0', '#FF66C3', '#00D9E9'], // Total 7 colors
          tooltip: {
            theme: 'dark',
            fillSeriesColor: false,
            style: {
              fontSize: '12px',
              fontFamily: 'inherit',
              color: '#fff',
            },
            custom: function({ series, seriesIndex, w }) {
              return `<div style="background-color: #000; padding: 5px; border-radius: 5px;">
                        <span style="color: #fff;">${w.globals.labels[seriesIndex]}: ${series[seriesIndex]}</span>
                      </div>`;
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      
});      
 

    useEffect(() => {
        const type = window.localStorage.getItem("type");
        //let company_code = 0;
        if (type === 'User') {
          const  company_code = window.localStorage.getItem("code");
            filterdata(company_code);
            unitWiseCompanyVisit(company_code)
            frequentVisitors(company_code)
            purposeOfVisit(company_code)
            purposeofvisittable(company_code)
            visitorsinUnit(company_code)
            companywise(company_code);
                hostwise(company_code);
        } else {
            const company_code = 0;
            filterdata(company_code);
            unitWiseCompanyVisit(company_code)
            frequentVisitors(company_code)
            purposeOfVisit(company_code)
            purposeofvisittable(company_code) 
            visitorsinUnit(company_code) 
            companywise(company_code);
                hostwise(company_code);
        }
        getCompanyList()
    }, [])

    const getCompanyList = async () => {
        let companyData = await fetch(config.mongo_endpoint + "api/v1/company/getall", {
            method: "GET",
            headers: { "x-access-token": window.localStorage.getItem("AgentToken"),}
        });

        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {
            const companylist = resultCompanyData.data;
            setCompanyDatas(companylist);
        }
    }

    const [data, setData] = useState([]);
    const [frequent, setFrequentData] = useState([]);

    async function filterdata(company_code) {

        const data = {
            company_code: company_code
        }
        axios.post(config.mongo_endpoint + 'api/v1/dashboard/report', data).then((resDash) => {
            let datas = [];
            const resultData = resDash.data;
            datas.push(resultData)
            setData(datas)
        })
    }


    async function frequentVisitors(company_code) {

        const data = {
            company_code: company_code
        }
        axios.post(config.mongo_endpoint + 'api/v1/dashboard/frequentvisitors', data).then((resDash) => {
            let datas = [];
            const resultData = resDash.data.data;
           // console.log("responsedddd:",resultData)
            // datas.push(resultData)
            setFrequentData(resultData)
        })
    }
    function unitWiseCompanyVisit(company_code) {

        const data = {
            company_code: company_code
        }

        // if(company_code !== 0){
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();
            
            // Get the first and last day of the current month
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);
            
            // Generate an array of all days in the current month
            const dayss = [];
            for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
                dayss.push(
                    new Date(day).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                    }).replace(/ /g, '-') // Format to DD-MMM and replace spaces with hyphens
                  );
            }
      
            // console.log(days);
        // }
        axios.post(config.mongo_endpoint + 'api/v1/dashboard/unitwisereport', data).then((resDash) => {
            const newLabelData = [];
            const newSeriesData = [];
            const resultData = resDash.data;
            if(resultData.chartData.length > 0){
                const datas = resultData.chartData;
            //  console
            //console.log(datas);
            setChartData(prevState => ({
                ...prevState,
                series: datas,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: dayss  // Update categories with newLabelData
                  }
                }
              }));
                //   console.log(labelData);

            }            
            
        })
    }

  
    function purposeOfVisit(company_code) {

        const data = {
            company_code: company_code
        }
        axios.post(config.mongo_endpoint + 'api/v1/dashboard/purposeofvisit', data).then((resDash) => {
            const resultData = resDash.data;
          //  console.log("PURP",resultData)
            if(resultData.status === "Success"){
                const datas = resultData.data;
              //  console.log("PURP",datas)
            //  console
            // console.log(datas);
            setPieData(prevState => ({
                ...prevState,
                series: datas.series,
                options: {
                  ...prevState.options,
                  labels: datas.label
                }
              }));
                //   console.log(labelData);

            }            
            
        })
    }


    function handleCompanyChange(event) {
        filterdata(event.target.value);
        unitWiseCompanyVisit(event.target.value)
        frequentVisitors(event.target.value)
        purposeOfVisit(event.target.value)
        purposeofvisittable(event.target.value)  
        visitorsinUnit(event.target.value) 
        companywise(event.target.value);
                hostwise(event.target.value);
    };
//table grid
 //purpose of visit
 async function purposeofvisittable(companyName) {

    try {

        const data = new FormData();
        data.append("company_code", companyName);

        const purposeData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/purposeofvisitdata', data, {
            headers: {
                  "x-access-token" : window.localStorage.getItem("AgentToken"),  
                'Content-Type': 'multipart/form-data',
            }
        });



        if (purposeData.status === 200) {

            const resultData = purposeData.data.data;

            setPurposeData(resultData)
            //console.log(resultData, 'resultData')
            
              

        }
    } catch (error) {

        console.error("Error fetching visitor data:", error);
        // Handle error as needed (e.g., show an error message)
    }
}  

async function visitorsinUnit(companyName) {

    try {

        const data = new FormData();
        data.append("company_code", companyName);

        const visitorData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/visitorsinunit', data, {
            headers: {
                  "x-access-token" : window.localStorage.getItem("AgentToken"),  
                'Content-Type': 'multipart/form-data',
            }
        });



        if (visitorData.status === 200) {

            const resultData = visitorData.data.data;

            setvisitorData(resultData)
            //console.log(resultData, 'resultData')
            
              

        }
    } catch (error) {

        console.error("Error fetching visitor data:", error);
        // Handle error as needed (e.g., show an error message)
    }
} 

    //companywise data
    async function companywise(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);



            const companyvisitorData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/companywisevisitors', data, {
                headers: {
                    //  "x-access-token" : window.localStorage.getItem("AgentToken"),  
                    'Content-Type': 'multipart/form-data',
                }
            });



            if (companyvisitorData.status === 200) {

                const companyvisitorDatas = companyvisitorData.data.data;

                setCompanyWiseData(companyvisitorDatas)
                console.log(companyvisitorDatas, 'cw')

            }
        } catch (error) {

            console.error("Error fetching visitor data:", error);
            // Handle error as needed (e.g., show an error message)
        }
    }
    async function hostwise(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);



            const hostData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/hostwisevisitors', data, {
                headers: {
                    //  "x-access-token" : window.localStorage.getItem("AgentToken"),  
                    'Content-Type': 'multipart/form-data',
                }
            });



            if (hostData.status === 200) {

                const hostDatas = hostData.data.data;

                setHostData(hostDatas)
               // console.log(hostDatas, 'hs')

            }
        } catch (error) {

            console.error("Error fetching visitor data:", error);
            // Handle error as needed (e.g., show an error message)
        }
    }

    return (
        <>

            <AgentMenu></AgentMenu>

            <div class="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div class="d-flex border-bottom pb-15 pt-15">
                        <div class="me-auto ">
                            <h2 class="page-title" style={{ fontSize: "20px" }}>Visitor Dashboard​</h2>

                        </div>

                    </div>
                    {obj.type === "Admin" &&
    
                        <Grid container spacing={2} justifyContent="flex-end" marginBottom={2}> {/* Adding marginBottom to create space */}
                            <Grid item xs={3}>
                                <Box>
                                    <FormControl fullWidth style={{ marginTop: '15px' }}>
                                        <InputLabel id="demo-simple-select-label">Company</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            size="small"
                                            name="company_name"
                                            label="Company"
                                            onChange={handleCompanyChange}
                                        >
                                            <MenuItem value={0}>Select Company</MenuItem>
                                            {companyDatas.map((res) => (
                                                <MenuItem key={res.company_code} value={res.company_code}>
                                                    {res.company_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
}

                    {data.map((resReu, index) => (
                        <div class="row g-6 mb-6">
                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">Total Month Visitors</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalVisitor}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash1} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">Today Visitors</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalVisitorToday}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash2} alt="" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">CheckIn Visitors</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalCheckInToday}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash3} alt="" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">CheckOut Visitors</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalCheckOutToday}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash3} alt="" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <br></br>

                    {usertype === 'User' && (
                    data.map((resReu, index) => (
                        
                        <div class="row g-6 mb-6">
                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">Visitors In Unit</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalVisitorinUnit}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash2} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )))}

                    <br></br>
                    <div class="row g-6 mb-6">
                        <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h6 class="page-title" style={{ fontSize: "20px" }}>Company  Wise Visitors (This Month)​</h6>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company  </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {companywiseData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (
                                        companywiseData.map((com, index) => (
                                            <TableRow>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{com.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{com.visit_count}</TableCell>

                                            </TableRow>
                                        ))
                                    )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    
                    <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Purpose of Visits (This Month)​</h2>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company </TableCell>

                                            <TableCell align="center" style={{ background: '#ddd' }}>Purpose of Visit </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {purposeData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (

                                        purposeData.map((purpose, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.purpose_of_visit}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.visit_count}</TableCell>
                                            </TableRow>
                                        ))
                                    )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                        <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Host Wise Visitors (This Month)​</h2>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Host Name </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company / Unit  </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {hostData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (
                                        hostData.map((host, index) => (
                                            <TableRow>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{host._id.meeting_person}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{host.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{host.visit_count}</TableCell>

                                            </TableRow>

                                        ))
                                    )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div class="col-xl-6 col-sm-8 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Frequent Visitors (This Month)​</h2>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Visitor Name </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company / Unit  </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {frequent.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (
                                    frequent.map((res, index) => (
                                        <TableRow>
                                        <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{res.visitor_name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{res.company_info}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{res.visit_count}</TableCell>

                                    </TableRow>
                                    ))
                                )}
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                        {usertype === 'User' && (
                        <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Visitors In Unit​</h2>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Visitor Name</TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Purpose of Visit </TableCell>
                                            

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {visitorData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (

                                        visitorData.map((purpose, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.visit_count}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.purpose_of_visit}</TableCell>
                                                
                                            </TableRow>
                                        ))
                                    )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        )}

                        </div>
                    <br></br> <br></br>
                    <div class="row g-6 mb-6">
                        <div class="col-xl-12 col-sm-12 col-12">
                       
                            <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={320} />
                        </div>
                        
                        <div class="col-xl-6 col-sm-4 col-12">
                        <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Purpose Of Visits (This Month)​</h2>

                                </div>
                                
                            </div>
                        <ReactApexChart options={pieData.options} series={pieData.series} type="pie" width={380} />
                        </div>
                     
                    </div>
                </div>
            </div>





        </>
    );
}

export default VisitorDashboard;