import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom"; // import react router dom

import VisitorDashboard from "./views/VisitorDashboard";
import Manninglogin from "./views/manning-login";
import VisitorReport from "./views/VisitorReport";
import ManageVisitors from "./views/ManageVisitors";
import AddAgent from "./views/Add-visitor";
import AddCompany from "./views/Add-company";
import ManageCompany from "./views/Manage-company";
import UpdateCompany from "./views/Add-company/UpdateCompany";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component

function App() {
  const loggedIn = window.localStorage.getItem('loggedIn');

  return (
    <BrowserRouter>
      <Routes>
      <Route
          path="/"
          element={<Manninglogin />}
        />
        <Route
          path="/login"
          element={loggedIn ? <Navigate to="/VisitorDashboard" /> : <Manninglogin />}
        />
        <Route
          path="/VisitorDashboard"
          element={<ProtectedRoute element={<VisitorDashboard />} />}
        />
        <Route
          path="/ManageVisitors"
          element={<ProtectedRoute element={<ManageVisitors />} />}
        />
        <Route
          path="/AddVisitor"
          element={<ProtectedRoute element={<AddAgent />} />}
        />
        <Route
          path="/ManageCompany"
          element={<ProtectedRoute element={<ManageCompany />} />}
        />
        <Route
          path="/AddCompany"
          element={<ProtectedRoute element={<AddCompany />} />}
        />
        <Route
          path="/UpdateCompany"
          element={<ProtectedRoute element={<UpdateCompany />} />}
        />
        <Route
          path="/VisitorReport"
          element={<ProtectedRoute element={<VisitorReport />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
